export const BASE_URL = 'https://api.onlychats.com';
// export const BASE_URL = 'http://localhost:5001';

// MONTHLY
export const SUBSCRIPTION_CREDITS = {
  STARTER: {
    VALUE: 150,
    BONUS: 0,
    TOTAL: 150,
  },
  PREMIUM: {
    VALUE: 250,
    BONUS: 20,
    TOTAL: 270,
  },
  VIP: {
    VALUE: 500,
    BONUS: 50,
    TOTAL: 550,
  },
};

export const INFLUENCER_CATEGORY = {
  PERSON: 'PERSON',
  ANIME: 'ANIME',
};
