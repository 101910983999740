import React from 'react';
import {
  Card,
  Image,
  InfoContainer,
  NameContainer,
  AboutMeText,
  NameText,
  StyledVerifiedIcon,
  AnimeWrapper,
  Shadow,
  IconsContainer,
} from './InfluencerCard.styles';
import { useNavigate } from 'react-router-dom';
import { INFLUENCER_CATEGORY } from '../../utils/constants';
import { ReactComponent as AnimeIcon } from '../.././assets/icons/anime.svg';
import { ReactComponent as MessageIcon } from '../.././assets/icons/message_1.svg';
import { ReactComponent as VideoIcon } from '../.././assets/icons/video_1.svg';
import { ReactComponent as CallIcon } from '../.././assets/icons/call_1.svg';

const InfluencerCard = ({
  name,
  isVerified,
  image,
  username,
  aspectRatio,
  age,
  aboutMe,
  innerRef,
  category,
  callOptions,
}) => {
  const navigate = useNavigate();

  const onCardClick = () => {
    navigate(`/influencers/${username}`);
  };

  return (
    <Card $aspectRatio={aspectRatio} onClick={onCardClick} ref={innerRef}>
      {category === INFLUENCER_CATEGORY.ANIME && (
        <AnimeWrapper>
          <AnimeIcon />
        </AnimeWrapper>
      )}
      <IconsContainer>
        <MessageIcon />
        <CallIcon />
        {callOptions?.isVideoCallEnabled && <VideoIcon />}
      </IconsContainer>
      <InfoContainer>
        <NameContainer>
          <NameText>
            {name}, {age}
          </NameText>
          {isVerified && <StyledVerifiedIcon />}
        </NameContainer>
        <AboutMeText>{aboutMe}</AboutMeText>
      </InfoContainer>
      <Image src={image} />
      <Shadow />
    </Card>
  );
};

export default InfluencerCard;
